@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import url('https://fonts.googleapis.com/css?family=Helvetica:300'); */


/*
Variables
 */

:root {
    --primary-color: #37474f;
    --accent-color: #2196f3;
    --accent-hover-color: #2196f3;
    --accent-light-color: #2196f3;
    --dark-ripple-color: #2196f3;
    --light-ripple-color: #2196f3;
    --primary-accent-focused-color: #2196f3;
    --secondary-color: #000;
    --gray-color: #9F9F9F;
    --gray-color-2: #EEEEEE;
    --tooltip-gray: #616161;
    --active-opacity: 0.87;
    --hover-opacity: 0.54;
    --inactive-opacity: 0.38;
    --disabled-opacity: 0.26;
    --line-divider-color: #eeeeee;
    --form-invalid: #ef5350;
    --form-required: #1294f7;
    --background-1: #F1F1F1;
    --background-2: #FAFAFA;
    --success: #5AB963;
    --error: #F95B45;
    --border-bottom: #E9E9E9;
    --left-drawer-color: #f3faff;
    --main-background: #fbfdff;
    --regular: 400;
    --medium-bold: 600;
    --medium: 500;
    --pivot-table-border-color: #ededed;
    --pivot-table-bg-color: #fafafa;
    /*Over riding Material Primary color*/
    --mdc-theme-primary: #2196f3!important;
    --mdc-ripple-surface-width: 56px;
    --mdc-ripple-surface-height: 56px;
    --mdc-ripple-fg-size: 33.6px;
    --mdc-ripple-fg-scale: 2.65464;
    --mdc-ripple-fg-translate-start: 8.21562px, 1.2px;
    --mdc-ripple-fg-translate-end: 11.2px, 11.2px;
}

.portrait,
.portrait-type,
.landscape,
.visibility-hidden {
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.print_display_only,
.display_none {
    display: none;
}

.display_flex {
    display: flex;
    flex-wrap: wrap;
}

.display_print_only {
    display: none;
}


/**
To hide the scrollbars
 */

.mdc-permanent-drawer::-webkit-scrollbar {
    display: none;
}


/* ::-webkit-scrollbar {
    display: none;
} */


/*
Main body
 */

body {
    background: #fbfdff;
    background: var(--main-background);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--regular);
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}


/**************************************************************************************************************************************************************/


/*
Login
 */


/* .login-wrapper {
    display: table;
    background: url('./svg/cloud.svg') repeat-x bottom;
    position: absolute;
    height: 100vh;
    width:100%;
    text-align: center;
}
.login-center-div {
    display: table-cell;
    vertical-align: middle;
    padding: 24px 0;
}
.login-logo {
    margin: 0 auto;
}
.login-logo img {
    height: 63px;
    width: 76px;
}
.login-title {
    margin: 127px auto 0;
}
.login-title h2 {
    font-weight: var(--regular);
}
.login-body {
    margin: 48px auto 127px;
    width: 30%;
}
.login-btn {
    background-color: var(--accent-color);
}
.input-wrapper {
    position: relative;
}
.input-wrapper input {
    line-height: 49px;
    margin: 0 auto ;
    width: 90%;
    font-size: 16px;
    padding: 30px 5% 0 5%;
    border: none;
    border-bottom: 1px solid var(--line-divider-color);
    outline:none;
}
.input-wrapper > label  {
    color:#999;
    font-size:16px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5%;
    top:36%;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
}
.input-wrapper > input:focus ~ label {
    top: 35%;
    font-size: 12px;
    transform: translateY(-35%);
    color: var(--accent-color)!important;
}
.input-wrapper > input:invalid ~ label{
    color: var(--form-invalid);
}
.focused-label {
    top: 35%!important;
    font-size: 12px!important;
    transform: translateY(-35%)!important;
    color: var(--accent-color)!important;
}
.login-form button {
    margin-top: 4px;
    width: 100%;
    line-height: 56px;
    height: 56px;
    font-size: 14px;
    font-weight:var(--medium);
    border-radius: 0;
    color: #ffffff;
}
.login-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.login-footer {
    color: #a0a0a0;
    font-weight: var(--medium);
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: #ffffff !important;
} */


/**************************************************************************************************************************************************************/


/*
Headers
*/

h1 {
    font-size: 24px;
    /*line-height: 1.33;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
    text-transform: uppercase;
}

h2 {
    font-size: 20px;
    font-weight: 500;
    font-weight: var(--medium);
    /*line-height: 1.4;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

h3 {
    font-size: 16px;
    /*line-height: 1.5;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

h4 {
    font-size: 14px;
    font-weight: 500;
    font-weight: var(--medium);
    /*line-height: 1.43;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

h5 {
    font-size: 14px;
    font-weight: 500;
    font-weight: var(--medium);
    /*line-height: 1.14;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

h6 {
    font-size: 12px;
    /*line-height: 1.33;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

.h7 {
    display: inline;
    font-size: 1.077em;
    font-weight: 500;
    font-weight: var(--medium);
    text-transform: uppercase;
    margin: 0;
}


/**************************************************************************************************************************************************************/


/**
Line dividers
 */

hr {
    background: #eeeeee;
    background: var(--line-divider-color);
}

.material-icons {
    cursor: pointer;
    vertical-align: middle;
    font-size: 20px;
    height: 24px;
    width: 24px;
    margin-left: 24px;
    color: #000;
    color: var(--secondary-color);
    opacity: 0.38;
    opacity: var(--inactive-opacity);
    text-align: center;
}

.material-icons:hover {
    opacity: 0.54;
    opacity: var(--hover-opacity);
}

.material-icons.active {
    opacity: 0.87;
    opacity: var(--active-opacity);
}

.mdc-fab__icon {
    margin: 17px;
    color: #fff;
    opacity: 1;
}

.mdc-fab {
    background-color: #2196f3;
    background-color: var(--accent-color);
    box-shadow: 0 6px 6px 0 rgba(33, 150, 243, 0.24), 0 0 6px 0 rgba(33, 150, 243, 0.12);
}

.mini-fab-button .mdc-fab--mini {
    width: 24px;
    height: 24px;
    box-shadow: none;
    left: 76px;
    top: 18px;
}

.mini-fab-button .material-icons {
    font-size: 14px;
    margin-top: 5px;
}


/**************************************************************************************************************************************************************/


/**
NavigationBar header
 */


/* .nav-bar {
    background: var(--primary-color);
    top: 0;
    color: #fff;
    width: 100%;
    position: fixed;
    z-index: 500;
    line-height: 64px;
    height: 64px;
    vertical-align: middle;
}
.nav-bar h1 {
    display: inline-block;
    color: #fff;
}
.nav-bar i {
    color: #fff;
    opacity:var(--active-opacity);
    vertical-align: middle;
}
.nav-bar i:hover {
    color: #fff;
    opacity:var(--active-opacity);
}
.nav-right-item {
    display: flex;
    float: right;
    margin-right: 24px;
    line-height: 64px;
    height: 64px;
}
.nav-bar .mdc-layout-grid {
  padding: 0px !important;
}
.nav-bar-center-item {
  text-align: center;
}
.nav-bar-center-item h1{
    padding-left: 40%;
  text-transform: unset;
  font-weight: bold;
  font-size: 20px;
}
.nav-bar-center-item span{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  opacity:.9;
}
.logo-font {
    margin-left: 24px;
    font-size: 20px;
    font-weight: lighter;
    text-transform: uppercase;
}
.logo-font strong {
    font-weight:bolder;
    text-transform: none;
} */


/**************************************************************************************************************************************************************/


/*
Logout Menu
 */

.logout-menu-avatar {
    height: 40px;
    right: 24px;
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    font-weight: var(--medium);
    text-align: center;
    cursor: pointer;
}

.logout-menu-icon {
    /*position: absolute;
    top:0;*/
    text-align: right;
    float: right;
    margin-left: 14px;
}

.logout-menu-icon .material-icons {
    opacity: unset;
}

.info-menu-icon {
    /*position: absolute;
    top:0;*/
    text-align: right;
    margin-right: 24px;
    float: right;
}

.info-menu-icon .material-icons {
    opacity: unset;
}

.logout-menu-list {
    position: absolute;
    background: #ffffff;
    outline: none;
    list-style: none;
    border-radius: 2px;
    right: 24px;
    top: 0;
    padding: 0;
    max-width: 500px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.18), 0 16px 16px 0 rgba(0, 0, 0, 0.24);
}

.logout-menu-list li {
    cursor: pointer;
    border-bottom: 1px solid #eeeeee;
    border-bottom: 1px solid var(--line-divider-color);
    padding: 12px 24px 6px 24px;
    font-size: 12px;
    line-height: 48px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
}

.logout-menu-list li:hover {
    background: #eeeeee;
    background: var(--line-divider-color);
}

.logout-menu-userinfo {
    float: right;
    text-align: right;
    font-weight: 500;
}

.logout-menu-userinfo ul {
    padding-left: 26px;
}

.logout-menu-userinfo ul li {
    font-size: 10px;
    line-height: 16px;
    list-style-type: none;
}

.logout-menu-userinfo ul li:first-child {
    padding-top: 3px;
}

.logout-menu-userinfo ul li:nth-child(2) {
    color: #fbfdff;
}

.logout-username,
.logout-role {
    margin: 0;
    padding: 0;
    line-height: 24px;
}

.logout-username {
    font-size: 16px;
}

.logout-distributor {
    opacity: 0.5;
}

.logout-role {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
}

.logout {
    line-height: 32px!important;
}

.grep {
    float: right !important;
    text-align: right;
}

.grep::after {
    content: ' ';
    border-right: 1px solid #fbfdff;
    opacity: 0.26;
    padding: 10px 0;
}


/**************************************************************************************************************************************************************/


/*
Content
 */

.content {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 64px);
}

.right-drawer {
    direction: ltr;
}

.right-drawer>.mdc-temporary-drawer__drawer {
    max-width: 354px!important;
}

.mdc-temporary-drawer .mdc-temporary-drawer__drawer {
    margin-top: 64px;
}

.mdc-temporary-drawer .mdc-temporary-drawer__drawer .filter-wrapper {
    margin-top: 24px;
}

.users .dialog-upperpart .mdc-textfield,
.receipt-dialog mdc-textfield {
    max-width: unset !important;
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
}

.cursor-not-allow {
    cursor: not-allowed;
}

.cursor-pointer {
    cursor: pointer !important;
}

.weight-400 {
    font-weight: 400 !important;
}

.pointer-event-none {
    pointer-events: none;
}


/* .mdc-temporary-drawer__drawer {
    max-width: 256px!important;
} */


/* .left-drawer {
    padding-top: 24px;
    padding-bottom: 24px;
} */


/*
.mdc-permanent-drawer .mdc-list-item {
    font-size: 14px;
    color: rgba(0,0,0,0.38);
    font-weight: var(--medium);

}
.content .mini-drawer .mdc-list-item {
  justify-content: center;

}
.content .max-drawer .mdc-list-item {
    padding-left: 24px;

}
.mdc-permanent-drawer .mdc-list-item .active-link, .mdc-permanent-drawer .mdc-list-item .active-link:focus  {
    font-weight: var(--medium);
    background: var(--accent-color);
    color:white;

}
.mdc-permanent-drawer__content {
    margin-top: unset;
    margin-bottom: auto;

}
.navigation-menu {
    margin: auto 0;

}
.active-link {
    border-left : none;

}
.navigation-menu .mdc-list {
  padding-top: 0px;

}
.stock-detail-radio .mdc-list {
    display: flex;

}
.mdc-list-item {
    line-height: 48px;
}
.mdc-list-item[dir=rtl] .mdc-list-item__start-detail, [dir=rtl] .mdc-list-item .mdc-list-item__start-detail {
    margin: 0;

}
.mdc-list-item.hide-overflow-text {
    line-height: 48px;
}

.content .mini-drawer{
  width: 64px !important;

}
.content .max-drawer .drawer-item{
  width: 58px;

}

.drawer-item-second {
  color:rgba(0,0,0,0.54);

}
.bg-accent-color .drawer-item-second{
  color: #ffffff;

}
.mdc-permanent-drawer {
   min-height:100vh;
    border-right: none;
    display: flex;
    width: 256px;
    background: var(--left-drawer-color);

}
.drawer-menu-inside-menu-icon {
    position: absolute;
    right: 0;
    margin-right: 8px;
    opacity: 1;

}
.drawer-menu-inside-menu {
    height: inherit;
    width: inherit;
    line-height: normal;
    display: block;
    padding: 0!important;

}
.drawer-menu-inside-menu:focus {
    background: #fff!important;
}
.drawer-menu-inside-menu > .mdc-list-item {
    padding: 0 32px!important;
}
.border-right-accent {
    border-right: 2px solid var(--accent-color);
}

.bg-accent-color {
    background:var(--accent-color);
    color:white !important;
}
 */

.content-body {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    flex: 1 1;
}

.content-body .app-body {
    height: calc(100vh - 74px);
    overflow: auto;
}

.pad-24-0-24-10 {
    padding: 24px;
    padding-top: 10px;
    padding-bottom: 0;
}

.pad-24 {
    padding: 24px;
}

.pad-r-l-24 {
    padding: 0 24px 0 24px;
}

.pad-b-24 {
    padding-bottom: 24px;
}

.height-50 {
    height: 50px !important;
}

.content-body>.mdc-layout-grid .no-end-list-table {
    max-height: calc(100vh - 188px) !important;
    overflow: auto;
}

.content-body>.mdc-layout-grid .outstanding-report .no-end-list-table,
.content-body>.mdc-layout-grid .ageing-report .no-end-list-table {
    max-height: calc(100vh - 253px) !important;
    overflow: auto;
}


/* for ird reports height table */

.content-body>.mdc-layout-grid .th-table-height,
.content-body>.mdc-layout-grid .upper-th-table-height {
    max-height: calc(100vh - 272px) !important;
    overflow: auto;
}


/* for ird reports height table end */


/* for sales person outstanding report */

.content-body>.mdc-layout-grid .sales-outstanding .no-end-list-table {
    max-height: calc(100vh - 270px) !important;
    overflow: auto;
}


/* for sales person outstanding report end */

.content-body>.mdc-layout-grid .end-list-table {
    max-height: calc(100vh - 256px) !important;
    overflow: auto;
}

.content-body>.mdc-layout-grid {
    padding: 0 !important;
}

.fixed-table-wrapper table {
    position: relative;
}

.fixed-table-wrapper table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    white-space: nowrap;
}

.right-arrow {
    content: '';
    background: url(/static/media/right_arrow_white.1063795a.svg) no-repeat 96% 50% #2196f3;
    background: url(/static/media/right_arrow_white.1063795a.svg) no-repeat 96% 50% var(--accent-color);
}

.menu-icon {
    margin-left: 20px !important;
    margin-right: 20px;
    font-size: 24px;
}


/**************************************************************************************************************************************************************/


/**
Margins and gaps
 */

.vertically-center-wrapper {
    line-height: 48px!important;
    height: 48px!important;
}

.vertically-center {
    vertical-align: middle;
    display: inline-block;
    line-height: normal;
}

.vertically-center-using-position {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.side-gaps {
    margin: 0 16px;
}

.right-side-gap {
    margin-right: 16px;
}

.left-side-gap {
    margin-left: 16px;
}

.side-nav {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.18), 0 16px 16px 0 rgba(0, 0, 0, 0.24);
}

.divider {
    margin: 24px 0;
    height: 1px;
    width: 100%;
    background: #eeeeee;
    background: var(--line-divider-color);
}

.margin-l-5 {
    margin-left: 5px;
}


/**************************************************************************************************************************************************************/


/**
Buttons
 */

button {
    display: inline;
    font-size: 1.077em;
    font-weight: 500;
    font-weight: var(--medium);
    text-transform: uppercase;
    margin-right: 8px!important;
}

.modal-btn {
    padding-left: 8px!important;
    padding-right: 8px!important;
}


/*.cancel-btn {
    color: #000;
}*/


/**************************************************************************************************************************************************************/


/**
Popup
*/


/*For the border and the color of the arrow of the tooltip*/

.ToolTipPortal>div>div>span:first-of-type {
    border-bottom: 10px solid #FFFEF2!important;
}

.ToolTipPortal>div>div>span:last-of-type {
    border-bottom: 10px solid rgba(0, 0, 0, 0.15)!important;
}

.popup-wrapper {
    max-height: 400px;
    overflow: auto;
    outline: none;
}

.popup-header {
    margin-bottom: 4px;
    font-weight: 500;
    font-weight: var(--medium);
}

.popup-header i {
    float: right;
}

.popup-date {
    margin-bottom: 8px;
    opacity: 0.54;
    opacity: var(--hover-opacity);
}


/**************************************************************************************************************************************************************/


/*
Badges
*/

.badge {
    cursor: pointer;
    float: none!important;
    font-size: 12px!important;
    font-weight: 500;
    font-weight: var(--medium);
    padding: 0 4px!important;
    border-radius: 2px;
    background: #9F9F9F;
    background: var(--gray-color);
    color: #ffffff!important;
    line-height: 15px;
    position: relative;
    text-align: right;
}

.badge:hover {
    background: #2196f3;
    background: var(--accent-color);
}

.active>.badge,
a.active>div>.badge {
    background: #2196f3;
    background: var(--accent-color);
}

.badge label {
    color: #ffffff;
}


/**************************************************************************************************************************************************************/


/**
Form
 */

.mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline) {
    width: 100%;
    max-width: 341px;
}

.mdc-textfield--upgraded:not(.mdc-textfield--fullwidth) {
    margin-top: 0!important;
}

.mdc-textfield__input {
    width: 100%;
    background-color: transparent;
    font-size: 16px;
}

.mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline):after {
    background-color: rgba(0, 0, 0, 0.38);
    -webkit-transform: translateY(100%) scaleY(1);
            transform: translateY(100%) scaleY(1);
}

.mdc-textfield__label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    bottom: 12px;
    -webkit-transform: translateY(-100%) scale(1);
            transform: translateY(-100%) scale(1);
}

.mdc-textfield__label--float-above {
    -webkit-transform: translateY(-100%) scale(1);
            transform: translateY(-100%) scale(1);
}

.mdc-textfield--focused .mdc-textfield__label {
    color: #2196f3;
    color: var(--accent-color);
}

.mdc-textfield--focused.mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline):after {
    background-color: #2196f3;
    background-color: var(--accent-color);
    -webkit-transform: translateY(100%) scale(1);
            transform: translateY(100%) scale(1);
}

.mdc-textfield--upgraded.right-align input.mdc-textfield__input {
    text-align: right;
}


/* label color */

label {
    cursor: pointer;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    color: rgba(0, 0, 0, var(--hover-opacity));
}

.input-field label:not(.label-icon).active {
    left: 0;
}

.input-field.col label {
    left: 0!important;
}


/**
For radio button
 */

.mdc-radio>label {
    cursor: pointer!important;
    margin-left: 30px!important;
}


/*.mdc-checkbox__background {*/


/*height: 35%;*/


/*width: 35%;*/


/*top: 13px;*/


/*}*/


/*.mdc-checkbox__background > label {*/


/*cursor: pointer!important;*/


/*position: fixed!important;*/


/*left: 40px!important;*/


/*}*/


/*.mdc-checkbox:after {*/


/*background: none!important;*/


/*}*/

.mdc-checkbox__background {
    left: 8px;
    top: 9px;
    width: 46%;
    height: 46.5%;
}

.mdc-form-field {
    width: 140px;
    padding-right: 20px;
}

.mdc-form-field>label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    padding-top: 1px;
}

.mdc-checkbox {
    width: 14px;
    height: 14px;
    padding: 7px;
    vertical-align: middle;
}

.mdc-checkbox:after {
    height: 104%;
    top: 1px;
    left: -2px;
    background-color: rgba(63, 81, 181, .14);
}

.mdc-checkbox:before {
    left: -0.6px;
    top: 0.5px;
    height: 105.5%;
    width: 97%;
    background-color: rgba(63, 81, 181, .14);
}

.mdc-checkbox__background:before {
    top: -1.08px;
    left: -0.4px;
    width: 101.2%;
    height: 118%;
    background-color: rgba(63, 81, 181, .14) !important;
}

.mdc-checkbox__background:after {
    background-color: rgba(63, 81, 181, .14) !important;
}

.mdc-checkbox__native-control:checked~.mdc-checkbox__background {
    background-color: #2196f3;
    background-color: var(--accent-color);
    border-color: #2196f3;
    border-color: var(--accent-color);
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: #2196f3;
    background-color: var(--accent-color);
    border-color: #2196f3;
    border-color: var(--accent-color);
}

.mdc-checkbox__native-control {
    background-color: rgba(63, 81, 181, .14);
    /*top:-1.08px;
  left:-0.5px;
  width:101.8%;
  height:116.8%;*/
}

.mdc-checkbox__checkmark {
    top: -1px;
}

.input[type="checkbox"]:focus {
    background-color: rgba(63, 81, 181, .14);
}


/*
For dropdown
 */

select {
    padding: 8px;
    cursor: pointer;
    float: left;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    outline: none;
    -webkit-appearance: none;
            appearance: none;
}

select option {
    display: none;
}

.Select-control {
    border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
    height: 34px;
}

.Select-arrow {
    border-color: rgba(0, 0, 0, 0.54) transparent transparent;
}

.Select--single>.Select-control .Select-value {
    padding-left: 0px;
    line-height: 28px;
}

.custom-select-wrapper {
    position: relative;
    cursor: pointer;
    outline: none;
}

.custom-select {
    background: url(/static/media/down_arrow.b6aa12d6.svg) no-repeat right;
    height: 48px;
    font-size: 15px;
    border-bottom: 1px solid #eeeeee;
    border-bottom: 1px solid var(--line-divider-color);
}

.custom-select-option {
    display: inline-block;
    line-height: 48px;
}

.custom-options-list-bottom {
    padding: 0;
    position: absolute;
    margin-top: 0;
    top: 0;
    width: 100%;
    z-index: 400;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.custom-options-list-top {
    padding: 0;
    position: absolute;
    margin-top: 0;
    top: -95px;
    width: 100%;
    z-index: 400;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.custom-options-list-top li,
.custom-options-list-bottom li {
    cursor: pointer;
    line-height: 48px;
    clear: both;
    list-style: none;
    padding: 0 16px 0 24px;
    color: #000;
    background: #fafafa;
}

.custom-options-list-bottom li:hover,
.custom-options-list-top li:hover {
    color: #000;
    background: #ededed;
}


/**************************************************************************************************************************************************************/


/*
Range
 */

.input-range__track {
    background: #9F9F9F;
    background: var(--gray-color);
}

.input-range__track--active {
    background: #2196f3;
    background: var(--accent-color);
}

.input-range__slider {
    background: #2196f3;
    background: var(--accent-color);
    border: 1px solid #2196f3;
    border: 1px solid var(--accent-hover-color);
}

.input-range__slider:active {
    color: #fff;
    display: flex;
    position: absolute;
    top: -10px;
    left: -12px;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-left: -2px;
    -webkit-transform: rotate(-45deg) scale(0) translate(0, 0);
    transition: -webkit-transform 100ms ease-out;
    transition: transform 100ms ease-out, -webkit-transform 100ms ease-out;
    border-radius: 50% 50% 50% 0;
    z-index: 1;
    -webkit-transform: rotate(-45deg) scale(1) translate(19px, -20px);
            transform: rotate(-45deg) scale(1) translate(19px, -20px);
}


/**************************************************************************************************************************************************************/


/*
Progress Bars
 */

.progress {
    background-color: #2196f3;
    background-color: var(--accent-light-color);
}

.progress .indeterminate {
    background-color: #2196f3;
    background-color: var(--accent-color);
}

.spinner-blue,
.spinner-blue-only {
    border-color: #2196f3;
    border-color: var(--accent-color);
}


/**************************************************************************************************************************************************************/


/**
Pagination
 */


/* .pagination-wrapper {
    text-align: center;
    font-size: 13px;
    line-height: 48px;
    padding: 0 24px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    color:rgba(0,0,0,0.54);
}
.pagination-wrapper i {
    cursor: pointer;
    opacity: var(--hover-opacity);
    margin: 0!important;
}
.pagination-total-pages {
    min-width: 40px;
    height: 48px;
}
.pagination-wrapper i:hover, .pagination-page:hover {
    color: var(--secondary-color);
    transition: background-color 1s ease!important;
}
.pagination-page {
    cursor: pointer;
    font-size: 13px;
    margin-left: 8px;
    background: none;
    color: var(--secondary-color);
    opacity: var(--hover-opacity);
}
.pagination-page:last-of-type {
    margin-right: 8px;
}
.pagination-page-active {
    color: var(--secondary-color);
    opacity:var(--active-opacity);
}
.pagination-wrapper .custom-select {
    display: inline-block;
    float: left;
    background: url('./svg/arrow_drop_down.svg') no-repeat right;
    min-width: 42px;
    height: 48px;
    font-size:13px;
    border-bottom: none;
}

.pagination-wrapper .custom-select-option {
    display: inline-block;
    line-height:48px;
    float: left;
    font-weight: 500;
}
.pagination-display-label {
    color: var(--secondary-color);
    opacity: var(--hover-opacity);
    font-size: 13px;
}
.pagination-label {
    cursor: pointer;
    color: var(--secondary-color);
    opacity: var(--inactive-opacity);
}
.pagination-label:hover {
    opacity: var(--hover-opacity);
}

.pagination-wrapper .custom-select-wrapper > .custom-options-list-bottom, .pagination-wrapper .custom-select-wrapper > .custom-options-list-top {
    width: 70px !important;
}
.pagination-wrapper .custom-select-wrapper > .custom-options-list-bottom li, .pagination-wrapper .custom-select-wrapper > .custom-options-list-top li{
    padding:0px 10px;
}
.pagination-wrapper .mdc-layout-grid {
  padding: 0px !important;
}

.page-leftside-wrapper-show{
  float: left;
  margin-right: 10px;
  font-size: 13px;
}
.nv-margin {
  margin-left: -60px;
} */


/**************************************************************************************************************************************************************/


/**
Datepicker
 */

.inline {
    display: inline;
}

.fixed-calendar-label {
    font-size: 14px;
    color: #000;
    color: var(--secondary-color);
    opacity: 0.54;
    opacity: var(--hover-opacity);
}

.fixed-calendar,
.fixed-calendar-active {
    margin: 0 8px 0 0;
}

.fixed-calendar-active {
    color: #2196f3!important;
    color: var(--accent-color)!important;
    opacity: initial;
}

.calendar-icon {
    border: none;
}

.calendar-arrow-icon {
    text-align: right;
    padding-bottom: 8px;
    border-bottom: 1px solid #9F9F9F;
    border-bottom: 1px solid var(--gray-color);
}

.date-range-picker-wrapper {
    position: relative;
    outline: none;
}

.date-picker-holder,
.date-picker-holder-active {
    cursor: pointer;
    padding-left: 24px;
}

.calendar-date {
    position: relative;
}

.calendar-status {
    width: 100px;
    position: absolute;
    top: -22px;
    font-size: 10px;
    color: #9F9F9F;
    color: var(--gray-color);
}

.date-picker-holder-active .calendar-status {
    color: #2196f3;
    color: var(--accent-color);
}

.date-picker-holder-active>.calendar-date,
.date-picker-holder-active>.calendar-arrow-icon {
    padding-bottom: 8px;
    border-bottom: 1px solid #2196f3;
    border-bottom: 1px solid var(--accent-color);
}

.date-picker-holder>.calendar-date {
    padding-bottom: 8px;
    border-bottom: 1px solid #9F9F9F;
    border-bottom: 1px solid var(--gray-color);
}

.date-range-picker-view-toggle,
.date-range-picker-view-toggle-active {
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    border: none;
    display: inline;
    border-radius: 0;
    outline: none;
    line-height: 3rem;
    width: 40%;
    margin: 0 0 20px 0;
    padding: 0 0 5px;
    box-sizing: content-box;
    transition: all 0.3s;
}

.date-range-picker-view-toggle-active {
    border-bottom: 1px solid #2196f3;
    border-bottom: 1px solid var(--accent-color);
}

.date-range-picker-view-toggle {
    border-bottom: 1px solid #9F9F9F;
    border-bottom: 1px solid var(--gray-color);
}

.rdr-Calendar {
    position: absolute;
    top: 56px;
    width: 295px!important;
    padding: 16px!important;
    margin-left: 24px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)!important;
    z-index: 50;
}

.rdr-DateRange {
    position: absolute;
    z-index: 300;
    top: 40px;
    left: 24px;
}

.is-inRange,
.is-selected {
    background: #2196f3!important;
    background: var(--accent-light-color)!important;
    color: #fff!important;
}

.rdr-Day:hover {
    background: #2196f3!important;
    background: var(--accent-color)!important;
    color: #fff!important;
    transition: background-color 1s ease!important;
}

.rdr-MonthAndYear-button.prev {
    cursor: pointer;
    background: url(/static/media/left_arrow.ce150880.svg) no-repeat center!important;
}

.rdr-MonthAndYear-button.next {
    cursor: pointer;
    background: url(/static/media/right_arrow.00b3f1f8.svg) no-repeat center!important;
}

.rdr-MonthAndYear-button.prev>i,
.rdr-MonthAndYear-button.next>i {
    display: none!important;
}

.date-picker-wrapper {
    position: relative;
    float: right;
}

.date-picker-wrapper .rdr-Calendar {
    right: 0px;
    line-height: 20px;
}

.date-picker-wrapper .date-picker-holder {
    color: rgba(0, 0, 0, 0.54);
}

.date-picker-wrapper .date-picker-holder>.calendar-date {
    border-bottom: none;
}

.date-picker-wrapper .calendar-arrow-icon {
    border-bottom: none;
}

.date-picker-wrapper .date-range-picker-view-toggle-active,
.date-picker-wrapper .date-picker-holder-active>.calendar-date {
    border-bottom: none;
}

.dialog-date-picker-wrapper {
    position: relative;
    float: left;
    top: 2px;
    display: block;
    width: 100%;
}

.dialog-date-picker-wrapper .rdr-Calendar {
    top: -40px;
}

.dialog-date-picker-wrapper .date-picker-holder {
    padding-left: 0px;
}

.dialog-date-picker-wrapper #date-range-picker-id {
    padding-bottom: 8px;
}

.dialog-date-picker-wrapper .calendar-arrow-icon {
    padding-bottom: 8px;
    padding-left: 30%;
}


/*.date-picker-wrapper .react-datepicker {
  width:100%;
  border:none;
  box-shadow:  0 0 2px 0 rgba(0,0,0,0.12),  0 2px 2px 0 rgba(0,0,0,0.24);
}
.date-picker-wrapper .react-datepicker__month-container {
  width:100%
}
.react-datepicker__navigation--previous {
  top: 16px;
}
.react-datepicker__navigation--next {
  top: 16px;
}
.react-datepicker__header {
  padding-top: 16px;
  background: #ffffff;
  border-bottom: none;
}
.react-datepicker__current-month {
  font-size: 13px;
  color:var(--secondary-color);
  opacity:var(--active-opacity);
}
.react-datepicker__day-names{
    white-space: nowrap;
    margin-top: 3px;
    line-height: 11px;
    padding: 0px 16px;
    text-align: left;
    color:rgba(0,0,0,0.54);
  }*/


/* .date-rendered {
    color:rgba(0,0,0,0.54);
} */


/**************************************************************************************************************************************************************/


/**
Cards
 */

.card {
    padding: 24px;
    margin-bottom: 24px;
    background: #fff;
    border: 1px solid #2196f3;
    border: 1px solid var(--light-ripple-color);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)
}

.card i {
    margin: 0 5px;
}

.card-header-bar {
    background: none;
    line-height: 62px;
}

.card-body {
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}


/**************************************************************************************************************************************************************/


/*
Grid
 */


/*.mdc-layout-grid__cell, .mdc-layout-grid__inner {*/


/*margin-bottom: 24px!important;*/


/*}*/


/*.mdc-layout-grid__cell:last-of-type {*/


/*margin-bottom: 0!important;*/


/*}*/

.content-body>.mdc-layout-grid {
    padding-top: 10px;
}

.border-black {
    border: 1px solid black;
}


/**************************************************************************************************************************************************************/


/**
Table
 */

table {
    background: #fff;
    border-collapse: collapse;
    text-align: left;
    font-size: 13px;
    min-width: 100%;
    table-layout: fixed;
    /*box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);*/
}

thead {
    border-bottom: none;
    /*font-weight: var(--medium);*/
}

td,
th {
    padding: 0 16px;
    white-space: nowrap;
}

th {
    background: #f4f4f4;
    color: rgba(0, 0, 0, 0.54);
    line-height: 48px;
    font-size: 14px;
    font-weight: normal;
}

td {
    line-height: 47px;
    font-weight: 400;
    font-weight: var(--regular);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

tr {
    border-bottom: 1px solid #f7f7f7;
}

td:first-of-type,
th:first-of-type {
    padding-left: 24px;
}

td:last-of-type,
th:last-of-type {
    padding-right: 24px;
}

.table-wrapper {
    padding-bottom: 100px;
    border-bottom: 1px solid #efefef;
}

.fixed-table-wrapper {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.card-body .fixed-table-wrapper {
    box-shadow: unset;
    max-height: unset !important;
    overflow: auto;
}

.fixed-table-header {
    table-layout: fixed;
}

.table-header {
    position: fixed;
    width: 100%;
    background: #f7f7f7;
    color: #9F9F9F;
    color: var(--gray-color);
    font-weight: normal;
    line-height: 48px;
    padding: 0 24px;
}

.table-footer {
    width: 100%;
    padding: 0 24px;
    background: #fff;
}

table tfoot.right-content-footer tr td:first-of-type {
    width: 80%;
}


/**************************************************************************************************************************************************************/


/*
Filter
 */

.filter-head {
    padding: 16px 24px;
    border-bottom: 1px solid #EEEEEE;
    border-bottom: 1px solid var(--gray-color-2);
}

.filter-body {
    padding: 16px 0;
}

.filter-title {
    display: block;
    cursor: pointer;
    border-bottom: 1px solid #eeeeee;
    border-bottom: 1px solid var(--line-divider-color);
    line-height: 48px;
    height: 48px;
    padding: 0 24px;
    font-size: 13px;
    font-weight: 500;
    font-weight: var(--medium);
}

.active-filter-title {
    background: url(/static/media/down_arrow.b6aa12d6.svg) no-repeat 95%;
}

.passive-filter-title {
    background: url(/static/media/up_arrow.01887027.svg) no-repeat 95%;
}

.filter-content {
    padding-left: 24px;
}


/**************************************************************************************************************************************************************/


/**m
Sidebar
*/

#sidenav-overlay {
    background: none!important;
}


/**************************************************************************************************************************************************************/


/**
Tabs
 */

.mdc-tab-bar {
    float: left;
}

.mdc-tab-header {
    vertical-align: middle;
}

.mdc-tab-body {
    clear: both;
}

.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:after {
    background: #2196f3;
    background: var(--accent-color);
}

.mdc-tab .badge {
    margin-left: 8px;
}

.mdc-tab--active .badge {
    background: #2196f3;
    background: var(--accent-color);
    opacity: 0.87;
    opacity: var(--active-opacity);
}


/**************************************************************************************************************************************************************/


/*
Modals
*/

.mdc-dialog__body .date-picker-holder {
    padding-left: 0px !important;
}

.mdc-dialog__surface {
    margin-top: 40px;
    max-width: 780px;
    min-width: 500px;
}

.mdc-dialog__header {
    border-bottom: 1px solid #eeeeee;
    border-bottom: 1px solid var(--line-divider-color);
    padding: 24px;
}

.mdc-dialog__body {
    border-bottom: 1px solid #eeeeee;
    border-bottom: 1px solid var(--line-divider-color);
    padding: 0px;
    margin: 0;
}

.mdc-dialog__body>div:not(:first-of-type) {
    margin-top: 20px;
}

.mdc-dialog__body--scrollable {
    top: 20px;
    max-height: calc(100vh - 250px);
}

.dialog-header-close {
    display: unset !important;
}

.dialog-header-content span {
    margin-right: 5px;
    font-size: 20px;
    font-weight: 500;
}

.image-dialog-body {
    padding: 4px 4px 3px;
}


/**************************************************************************************************************************************************************/


/**
Accordion
 */

.Collapsible__trigger {
    display: block;
    cursor: pointer;
    border-bottom: 1px solid #eeeeee;
    border-bottom: 1px solid var(--line-divider-color);
    line-height: 48px;
    height: 48px;
    padding: 0 24px;
    font-size: 13px;
    font-weight: 500;
    font-weight: var(--medium);
    background: url(/static/media/down_arrow.b6aa12d6.svg) no-repeat 95%;
}

.Collapsible__trigger.is-open {
    background: url(/static/media/up_arrow.01887027.svg) no-repeat 95%;
}

.Collapsible__contentOuter {
    transition: max-height 0.2s ease-out;
    font-size: 13px;
    font-weight: 500;
    font-weight: var(--medium);
    padding: 0 16px;
}


/**************************************************************************************************************************************************************/

.no-margin {
    margin: 0!important;
}

.no-margin-left {
    margin-left: 0px !important;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.no-padding {
    padding: 0!important;
}

.default-horizontal-margin-24 {
    margin: 0 24px;
}

.default-horizontal-margin-16 {
    margin: 0 16px;
}

.default-horizontal-margin-12 {
    margin: 0 12px;
}

.default-horizontal-margin-8 {
    margin: 0 8px;
}

.default-vertical-margin-24 {
    margin: 24px 0;
}

.default-vertical-margin-16 {
    margin: 16px 0;
}

.default-vertical-margin-12 {
    margin: 12px 0;
}

.default-vertical-margin-8 {
    margin: 8px 0;
}

.default-margin-24 {
    margin: 24px;
}

.default-left-margin-24 {
    margin-left: 24px;
}

.default-left-margin-9 {
    margin-left: 9px;
}

.default-margin-top-12 {
    margin-top: 12px;
}

.default-margin-top-2 {
    margin-top: 2px;
}

.default-margin-top-3 {
    margin-top: 3px;
}

.default-margin-top-24 {
    margin-top: 24px !important;
}

.default-margin-top-7 {
    margin-top: 7px;
}

.default-top-padding-12 {
    padding-top: 12.5px;
}

.default-horizontal-padding-24 {
    padding: 0 24px;
}

.default-horizontal-padding-16 {
    padding: 0 16px;
}

.default-horizontal-padding-8 {
    padding: 0 8px;
}

.default-vertical-padding-24 {
    padding: 24px 0;
}

.default-vertical-padding-16 {
    padding: 16px 0;
}

.default-vertical-padding-8 {
    padding: 8px 0;
}

.margin-left-24 {
    margin-left: 24px;
}

.margin-right-24 {
    margin-right: 24px;
}

.margin-right-0 {
    margin-right: 0px !important;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.margin-b-2 {
    margin-bottom: 2px !important;
}

.padding-bottom-0 {
    padding-bottom: 0px;
}

.padding-top-24 {
    padding-top: 24px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.padding-l-2 {
    padding-left: 2px !important;
}

.padding-r-4 {
    padding-right: 4px !important;
}

.padding-r-8 {
    padding-right: 8px !important;
}

.padding-r-24 {
    padding-right: 24px !important;
}

.padding-r-8 {
    padding-right: 8px !important;
    ;
}

.padding-l-24 {
    padding-left: 24px !important;
}

.padding-l-0 {
    padding-left: 0px !important;
}

.padding-top-8 {
    padding-top: 8px;
}

.padding-b-8 {
    padding-bottom: 8px;
}

.padding-b-12 {
    padding-bottom: 12px !important;
}

.padding-top-18 {
    padding-top: 18px !important;
}

.null {
    height: 0!important;
    width: 0!important;
}

.cursor-default {
    cursor: default!important;
}

.center-margin {
    margin: 0 auto;
}

.relative {
    position: relative;
}

.center-align {
    text-align: center;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.full-opacity-icon .material-icons {
    opacity: 1;
}

.hide-overflow-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.success-color {
    color: #5AB963;
    color: var(--success);
}

.failure-color {
    color: #F95B45;
    color: var(--error);
}

.fs-14 {
    font-size: 14px;
}

.border-bottom {
    border-bottom: 1px solid #E9E9E9;
    border-bottom: 1px solid var(--border-bottom);
}

.last-tr-without-border tbody>tr:last-child {
    border-bottom: none;
}

.hide-overflow-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.image-thumbnail {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.image-thumbnail .material-icons {
    margin-left: 0px;
}

.image-yes .material-icons {
    opacity: 0.54;
    opacity: var(--hover-opacity);
}

.image-no .material-icons {
    opacity: 0.26;
    opacity: var(--disabled-opacity);
    cursor: default;
}


/*Image Gallery*/

.image-gallery-play-button {
    display: none;
}


/*.image-gallery-fullscreen-button {
  top:8px !important;
  bottom:unset;
  right:-10px;
  position: fixed;
  font-size: 24px;
}*/

.image-gallery-fullscreen-button {
    top: 11.4px !important;
    bottom: unset;
    right: 35px;
    position: fixed;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
    color: #dddddd;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    top: 55%;
    font-size: 48px;
    position: fixed;
}

.image-gallery-bullets {
    bottom: 20px;
    position: fixed;
}

.image-gallery-play-button::before {
    font-size: 30px;
}

.image-gallery-fullscreen-button::before {
    font-size: 18px;
    cursor: pointer;
    color: #000;
    color: var(--secondary-color);
    opacity: 0.38;
    opacity: var(--inactive-opacity);
    text-shadow: none;
}

.image-gallery-fullscreen-button:hover::before {
    -webkit-transform: none;
            transform: none;
    color: #000;
    color: var(--secondary-color);
    opacity: 0.54;
    opacity: var(--hover-opacity);
}

.bill-summary-input {
    width: 40px;
}

.margin-auto {
    margin: auto;
}

.display-inline-table {
    display: inline-table;
    text-align: left;
}

.no-pointer-events {
    pointer-events: none;
}


/**********/


/*header-menu */

.search-icon-wrapper {
    background: #f6f6f6;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    transition: 5s ease-in-out;
    transition-delay: 1s;
}

.search-icon-wrapper .search-icon {
    position: absolute;
    top: 7px;
    left: -19.5px;
    font-size: 20px !important;
}

.cross-icon {
    margin-left: 0px;
    position: absolute;
    top: 20px;
    right: -12px;
}


/************************************************************************************************************************************************/


/** input**/

.input-field {
    /*position: relative;*/
    /*margin-top: 1rem; */
}

input[type=text],
input[type=number] {
    /*background-color: transparent;
border: none;
border-bottom: 1px solid #9e9e9e;
border-radius: 0;
outline: none;
height: 3rem;
width: 100%;
font-size: 1rem;
margin: 0 0 20px 0;
padding: 0;
-webkit-box-shadow: none;
box-shadow: none;
-webkit-box-sizing: content-box;
box-sizing: content-box;
-webkit-transition: all 0.3s;
transition: all 0.3s;*/
}


/** Short hand css**/

.right-align {
    text-align: right;
}

.right {
    float: right;
}

.left-align {
    text-align: left;
}

.center-align {
    text-align: center;
}

.active-opacity-text {
    color: rgba(0, 0, 0, 0.54);
}

.check {
    border-bottom: 1px solid red;
}


/** Sales Invoice **/

.header-menu {
    clear: both;
}


/* .header-menu-left {
  float: left;
}
.header-menu-right {
  float: right;
} */

.date-wrapper {
    width: 60%;
    float: right;
}

.top-search-input-wrapper {
    float: right;
    position: relative;
}

.input-inactive {
    transition: all .4s ease-out;
    width: 0% !important;
}

.input-active {
    transition: all .4s ease-in-out;
    width: 78% !important;
}


/*.top-search-input-wrapper .mdc-textfield__input {
  transition:display 40s;
  transition-delay: 40s;
}*/


/** New Invoice **/

.three-input-wrapper {
    padding: 12px 0px 0px;
}

.total-section-wrapper {
    font-size: 12px;
    padding-bottom: 15px;
}

.total-section-wrapper .mdc-layout-grid:not(:first-child) {
    padding: 10px 24px 0px;
    /*height:54px;*/
}

.total-section-wrapper .mdc-layout-grid:first-child {
    padding: 24px 24px 0px;
    /*height: 54px;*/
}

.total-section-wrapper .Select-menu-outer ul li {
    text-align: left;
}

.total-section-wrapper .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline) {
    width: 74px;
    height: 34px;
    margin-bottom: 0px;
}

.total-section-wrapper .mdc-textfield__input {
    text-align: right;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 0 6px;
}

.total-section-wrapper .Select-control {
    background: transparent;
}

.Select-control {
    border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
}

.Select-arrow {
    border-color: rgba(0, 0, 0, 0.54) transparent transparent;
}

.total-section-wrapper .Select-control {
    height: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
}

.total-section-wrapper .Select--single>.Select-control .Select-value {
    text-align: left;
    line-height: 32px;
}

.total-section-wrapper .Select-input {
    height: 28px;
    margin-right: 9px;
    vertical-align: baseline;
}

.total-section-wrapper .Select-arrow-zone {
    width: 20px;
    padding-right: 0px;
}

.total-section-wrapper .Select-arrow {
    border-width: 3.5px 3.5px 2px;
}

.hr {
    margin: 20px 0px 3px;
    border-bottom: 1px solid #efefef;
}

.font-bold {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.88);
}

.font-medium {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
}

.newinvoice-btn-wrapper .mdc-layout-grid {
    padding-right: 0px;
}

.stock-journal-create+.newinvoice-btn-wrapper .mdc-layout-grid {
    padding-right: 24px;
}

.modal-btn {
    font-size: 500;
    font-size: var(--medium);
    min-width: 64px;
    border-radius: 2px;
}

.newinvoice-btn-wrapper .save-btn {
    margin-right: 0px !important;
}

.save-btn {
    color: #ffffff !important;
    background: #2196f3 !important;
    background: var(--accent-color) !important;
    margin-left: 8px !important;
}

.cancel-btn {
    margin-right: 8px;
    background: rgba(153, 153, 153, 0.2) !important;
    color: rgba(0, 0, 0, 0.38) !important;
}


/** dialog box **/

.dialog-upperpart {
    border-bottom: 1px solid #eeeeee;
    border-bottom: 1px solid var(--line-divider-color);
    padding: 24px;
}

.dialog-detail {
    font-size: 16px;
    display: block;
    margin-top: 4px;
}

.accent-color {
    color: #2196f3;
    color: var(--accent-color);
}

.dialog-stock-detail-color {
    color: #ff2d55;
}

.dialog-lowerpart {
    padding: 0px 24px;
    margin-top: 4px !important;
}

.dialog-ok {
    color: #2196f3 !important;
    color: var(--accent-color) !important;
    background: transparent;
}

.dialog-cancel {
    color: rgba(0, 0, 0, 0.38) !important;
    background: transparent;
}

.mdc-button:before {
    background: transparent !important;
}

.quantity-type-select {
    position: absolute;
    top: 19px;
    right: 0px;
    padding-left: 8px;
    border-left: 1px solid #eeeeee;
    border-bottom: none !important;
    width: 60px;
    font-size: 10px;
    height: 25px;
}

.quantity-type-select .Select-arrow-zone {
    padding-left: 5px;
    padding-top: 7px;
    padding-right: 7.5px;
}

.quantity-type-select .Select-control {
    border-bottom: none !important;
    height: 25px;
}


/*.quantity-type-select .Select--single > .Select-control .Single-value {
  line-height: 29px;
}*/

.quantity-type-select .Select-control .Select-value {
    line-height: 28px;
}

.quantity-type-select .Select-input {
    height: 12px;
}

.quantity-type-select .Select-input>input {
    padding: 0px;
}

.has-value.Select--single>.Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label {
    color: rgba(0, 0, 0, 0.87);
}

.relative-container {
    position: relative;
}

.mdc-button:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 3px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    -webkit-transform: scale(1, 1) translate(-50%);
            transform: scale(1, 1) translate(-50%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
}

.save-btn:after {
    background: rgba(217, 237, 253, 0.6);
}

.dialog-ok:after {
    background: rgba(37, 152, 243, 0.32);
}

.cancel-btn:after {
    background: rgba(136, 136, 136, 0.25);
}

@-webkit-keyframes ripple {
    0% {
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        -webkit-transform: scale(25, 25);
                transform: scale(25, 25);
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(40, 40);
                transform: scale(40, 40);
    }
}

@keyframes ripple {
    0% {
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        -webkit-transform: scale(25, 25);
                transform: scale(25, 25);
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(40, 40);
                transform: scale(40, 40);
    }
}

.mdc-button:focus:not(:active)::after {
    -webkit-animation: ripple 1s ease-out;
            animation: ripple 1s ease-out;
}

.input-select .Select-control {
    height: 30px;
    background-color: transparent;
}

.input-select .Select-input {
    height: 28px;
}

.header-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 8px;
}

.header-title-content {
    display: block;
    font-size: 12px;
    margin-bottom: 6px;
}

.header-bar {
    border-bottom: 1px solid #efefef;
}

.header-middle-title {}

.header-middle-content {
    border-bottom: 1px solid #efefef;
    padding: 30px 0px;
    text-align: center;
}

.btn-disabled {
    pointer-events: none;
    opacity: 0.26;
    opacity: var(--disabled-opacity);
}

.inp-disabled {
    pointer-events: none;
    opacity: 0.54;
    opacity: var(--hover-opacity);
}

.input-select .Select-control {
    height: 29px;
    background-color: transparent;
}

.input-select .Select-input {
    height: 28px;
}


/* .header-bar {
  margin-top: 14px;
} */

.header-title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 6px;
    font-weight: 400;
}

.header-title-content {
    display: block;
    font-size: 12px;
    margin-bottom: 6px;
}

.header-bar {
    border-bottom: 1px solid #efefef;
}

.header-middle-title {
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.header-middle-title h2 {
    font-size: 24px;
}

.header-right-content {
    font-size: 14px;
    display: block;
}

.header-right-content .date {
    display: block;
}

.print-invoice .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline):after {
    background-color: transparent !important;
}

.clearfix {
    clear: both;
    display: block;
    content: '';
}

.clearfix::after {
    clear: both;
    display: block;
    content: '';
}

.header-left {
    float: left;
}

.header-right {
    float: right;
    color: rgba(0, 0, 0, 0.54);
}


/*sales vat register table*/

.table-border-bottom {
    border-bottom: 1px solid #e6e3e3;
}

th.table-cell {
    width: 70%;
    border-right: 1px solid #e6e3e3;
}

th.table-2-cell {
    width: 30%;
}

.assignSkuDialog .mdc-dialog__body--scrollable {
    min-height: 300px;
}

.list-header {
    margin-top: 2px;
}

.list-with-checkbox .mdc-form-field {
    display: block;
    width: 100%;
}

.list-with-checkbox .mdc-checkbox {
    padding: 8px;
    width: 16px;
    height: 15px;
}

.list-with-checkbox .mdc-form-field>label {
    font-size: 14px;
    padding-top: 5px;
}

.list-with-checkbox .mdc-checkbox::before,
.list-with-checkbox .mdc-checkbox::after {
    left: -1px;
    top: 0px;
    height: 100%;
    width: 100%;
}

.list-with-checkbox .mdc-checkbox__background::before {
    left: -1px;
    top: 0px;
    height: 100%;
    width: 100%;
}

.mdc-textfield {
    margin-bottom: 6px;
}

.Select {
    margin-bottom: 6px;
}

.mdc-textfield--invalid:not(.mdc-textfield--focused) .mdc-textfield__label {
    color: rgba(0, 0, 0, 0.54);
    color: rgba(0, 0, 0, var(--hover-opacity));
}

.mdc-textfield--invalid:not(.mdc-textfield--focused):after {
    background-color: #d50000 !important;
}

.invalid.mdc-textfield:after {
    background-color: #d50000 !important;
}

.error-message {
    display: block;
    margin-top: 0px;
    color: #d50000;
    font-size: 10px;
    line-height: 13px;
}

.billing-required .mdc-textfield__input:required+.mdc-textfield__label:after {
    display: none;
}


/* Radio-list*/

.radio-list-wrapper {
    padding: 6px 24px;
    border-bottom: 1px solid #efefef;
}

.radio-list-wrapper .mdc-list {
    padding: 0px;
}

.radio-list-wrapper .mdc-form-field {
    padding-right: 0;
    min-width: 100px;
    width: auto;
}

.radio-list-wrapper .mdc-radio {
    width: 36px;
    height: 36px;
}

.radio-list-wrapper .mdc-radio:before,
.radio-list-wrapper .mdc-radio:after {
    top: 1px;
}

.radio-list-wrapper .mdc-list-item,
.radio-list-wrapper .mdc-list {
    display: flex;
    flex-wrap: wrap;
}

.card-body>.radio-list-wrapper .mdc-list-item {
    overflow: unset;
    height: unset;
}

.card-body>.radio-list-wrapper .mdc-list-item label {
    white-space: normal;
}

.radio-disabled {
    pointer-events: none;
    opacity: 0.26;
    opacity: var(--disabled-opacity);
}

div.radio-disabled+label {
    pointer-events: none !important;
    opacity: 0.26!important;
    opacity: var(--disabled-opacity)!important;
}

div.active-radio+label {
    color: rgba(0, 0, 0, 0.87);
    color: rgba(0, 0, 0, var(--active-opacity));
}

.block-overlay {
    background: rgba(0, 0, 0, 0.03);
    pointer-events: none;
}

.block-overlay .three-input-wrapper .select-css .zindex-2__control,
.block-overlay .total-section-wrapper .select-css .zindex-2__control {
    pointer-events: none;
    background-color: #fafafa;
}


/*checkbox*/

.common-checkbox .mdc-checkbox::before,
.common-checkbox .mdc-checkbox::after,
.common-checkbox .mdc-checkbox__background::before,
.common-checkbox .mdc-checkbox__background::after {
    width: 100%;
    height: 100%;
    left: 0px;
}

.common-checkbox .mdc-checkbox__background::before,
.common-checkbox .mdc-checkbox__background::after {
    top: 0px;
}


/*Message Wrapper */

.message-wrapper {
    background: rgba(239, 239, 239, 0.8);
    padding: 20px;
}

.message-title {
    font-size: 16px;
}

.sub-message {
    color: rgba(0, 0, 0, 0.54);
    line-height: 20px;
    font-size: 12px;
    font-weight: normal;
}

.ledger-type-radio-group {
    padding: 0px !important;
}

.ledger-type-radio-group .mdc-list-item {
    display: inline-block;
}

.ledger-type-radio-group .mdc-form-field {
    width: auto;
}

.ledger-type-radio-group .mdc-radio {
    width: 36px;
    height: 36px;
}

.message-snackbar .mdc-snackbar--active {
    bottom: 4%;
    left: auto;
    -webkit-transform: translate(-10%, 0);
            transform: translate(-10%, 0);
    transition: webkit-transform .25s cubic-bezier(.4, 0, 1, 1) 0ms;
    transition: -webkit-transform .25s cubic-bezier(.4, 0, 1, 1) 0ms;
    transition: transform .25s cubic-bezier(.4, 0, 1, 1) 0ms;
    transition: transform .25s cubic-bezier(.4, 0, 1, 1) 0ms, -webkit-transform .25s cubic-bezier(.4, 0, 1, 1) 0ms;
    /*transition: transform 0.5s;*/
    line-height: 48px;
    padding: 0 20px 0 24px;
    background: #434343;
    min-width: 140px;
    opacity: 1;
}

.mdc-snackbar {
    left: auto;
    z-index: 9999;
    opacity: 1;
    -webkit-transform: translate(-10%, 100%);
            transform: translate(-10%, 100%);
    transition: webkit-transform .25s cubic-bezier(.4, 0, 1, 1) 1ms;
    transition: -webkit-transform .25s cubic-bezier(.4, 0, 1, 1) 1ms;
    transition: transform .25s cubic-bezier(.4, 0, 1, 1) 1ms;
    transition: transform .25s cubic-bezier(.4, 0, 1, 1) 1ms, -webkit-transform .25s cubic-bezier(.4, 0, 1, 1) 1ms;
    /*transition: transform 1s ease-in-out 0.1s;*/
    min-width: 140px;
}

.message-snackbar .success-message .mdc-snackbar__action-button {
    display: none !important;
}

.message-snackbar .mdc-snackbar__action-button {
    background: none;
    border: none;
    margin-left: 48px;
    margin-right: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #2196f3;
    color: var(--primary-accent-focused-color);
}

.mdc-snackbar .mdc-snackbar__text {
    font-size: 14px;
    font-weight: 400;
    color: #F8F8F8;
}

.print-active {
    display: block;
}

.display-block {
    display: block;
}

.no-print {
    display: none;
}

.display-inline-block {
    display: inline-block;
    width: 100%;
}


/* @page {
    margin: 0 !important;
    size: A4 portrait !important;
} */

.span-wrapper {
    float: left;
    width: 25%;
}

table.print-active th,
table.print-active td {
    line-height: unset;
    background: unset;
    border-bottom: none;
    padding-left: 0px;
}

table.header>thead th:first-child {
    width: 54%;
    padding: 0px;
}

table.footer>tfoot td {
    width: 40%;
    color: #000000 !important;
    opacity: 0.8 !important;
    padding-right: 0 !important;
}


/* .download-menu {
    position: relative;
} */


/* .header-menu-right .mdc-menu-anchor {
    position: absolute;
} */

.header-menu-right .mdc-simple-menu--open-from-top-right {
    right: -42px !important;
    background: #fafafa;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12);
}

.header-menu-right .mdc-simple-menu .mdc-list-item {
    font-size: 13px;
}

.header-menu-right .mdc-simple-menu .mdc-list-item:active:before {
    background: rgba(238, 238, 238, 1) !important;
}

.header-menu-right .mdc-simple-menu .mdc-list {
    padding: 0px;
}

table.log-table {
    table-layout: fixed;
    width: 100%;
}

table.log-table td,
table.log-table th {
    padding: 0 16px;
    white-space: normal;
}

table.log-table th {
    line-height: unset;
    font-weight: normal;
    width: 124px;
    padding: 14px 12px;
}

table.log-table td {
    vertical-align: top;
    line-height: unset;
    font-weight: 400;
    font-weight: var(--regular);
    padding: 10px 12px;
}

table.log-table td:nth-of-type(5),
table.log-table th:nth-of-type(5) {
    width: 324px !important;
}

table.log-table th:first-of-type,
table.log-table td:first-of-type {
    padding-left: 24px;
    width: 50px !important;
}

.cash-checkbox {
    position: relative;
    top: 9px;
}

.cash-checkbox .mdc-checkbox:before {
    left: 0px;
    top: 0.5px;
    height: 100.5%;
    width: 100%;
    background-color: rgba(63, 81, 181, .14);
}

.cash-checkbox .mdc-checkbox:after {
    left: 0px;
    top: 0.5px;
    height: 100.5%;
    width: 100%;
    background-color: rgba(63, 81, 181, .14);
}

.single-right-menu {
    right: -35px;
    left: unset !important;
}

.overflow-scrollable {
    overflow: auto !important;
}

.customer-select-inp {
    position: relative;
    top: -7px;
}

.select-custom-label {
    position: relative;
    top: -3px
}

.textbox {
    max-width: unset!important;
}

.table-input-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
}

.table-input-right .mdc-textfield--upgraded {
    height: 40px !important;
}

.hr-scrollable-table {
    overflow-x: auto;
}


/* added */

.stock-ledger.ledger-report .fixed-table-wrapper table tbody td,
.svr-table tbody tr,
.pvr-table tbody tr,
.prvr-table tbody tr,
.vat-report-table tbody tr {
    cursor: unset;
}

.sku table tbody tr td,
.stock-ledger .fixed-table-wrapper table tbody td,
.journal-voucher table tbody td,
.ledger-report table tbody td {
    cursor: pointer;
}

.customer-ledger .dialog-upperpart .row {
    border-bottom: 1px solid #eeeeee;
    border-bottom: 1px solid var(--line-divider-color);
    padding: 24px;
}

.customer-ledger .dialog-upperpart .row:last-child {
    border-bottom: 0;
}

:is(.customer-ledger,
.users,
.srn-create) .mdc-dialog__surface .mdc-dialog__body .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 50px;
}

.table-scroll {
    overflow: auto;
}

.header-menu-right .mdc-simple-menu .mdc-list-item {
    font-size: 13px;
}

.outstanding-report .header-menu-right,
.card-body .header-menu-right,
.ageing-report .header-menu-right {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin-top: 5px;
    position: relative;
}

.outstanding-report .header-menu-right .mdc-menu-anchor,
.card-body .header-menu-right .mdc-menu-anchor,
.ageing-report .header-menu-right .mdc-menu-anchor {
    position: unset;
}

.outstanding-report .header-menu-right .transaction-download,
.card-body .header-menu-right .transaction-download,
.ageing-report .header-menu-right .transaction-download {
    left: unset !important;
    right: 0;
    top: 29px !important;
}

.outstanding-report .transaction-download ul,
.card-body .header-menu-right .mdc-simple-menu .mdc-list {
    -webkit-transform: unset !important;
            transform: unset !important;
}

.ageing-report .header-menu-right .header-menu-left .mdc-textfield {
    height: unset;
}

.ageing-report .header-menu-right .header-menu-left {
    display: flex;
}

.ageing-report .header-menu-right .header-menu-left {
    position: relative;
    top: -6px;
}

.ageing-report .header-menu-right .header-menu-left .input-active {
    width: unset !important;
    flex: 1 1;
    position: relative;
    margin-left: 10px;
}

.ageing-report .header-menu-right .header-menu-left .input-active i {
    position: absolute;
    top: 0;
    right: -3px;
}


/* create-grn styling */

.create-grn .form-wrap {
    display: flex;
    flex-wrap: wrap;
    height: 48px;
}

.create-grn .form-wrap .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 36px;
}

.create-grn .form-wrap .Select {
    margin: 0;
}

.create-grn .form-wrap span {
    flex: 1 1;
}

.create-grn .form-wrap .mini-fab-button button {
    left: 9px;
    top: 4px;
}

.create-grn .form-wrap .mini-fab-button {
    flex: unset;
}

.create-grn .form-wrap .disabled .material-icons {
    cursor: not-allowed;
}

.create-grn .form-wrap .disabled {
    background-color: #B3CDFF;
    opacity: 0.7;
    cursor: not-allowed;
}

.create-grn .select-grn .mdc-layout-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.create-grn .select-grn .select-grn-title {
    margin-bottom: 16px;
}

.create-grn .width-100 .mdc-layout-grid__inner {
    width: 100%;
}

.create-grn .date-batch-dialog .mdc-layout-grid__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 65.66%;
}

.grn-create .row_one+.row .grid-padding {
    padding: 0;
}

.grn-create .row_one .date-range-picker-wrapper .default-vertical-padding-24 {
    padding-bottom: 0;
}

.grn-create .row_one .select-grn-title {
    margin-bottom: 32px;
}

.grn-create .mdc-dialog__surface .mdc-dialog__body .row_three .label {
    top: -5px;
    position: relative;
}

.grn-create .mdc-dialog__surface .mdc-dialog__body .row_three .mdc-textfield {
    height: 49px !important;
}

.table-scroll {
    overflow: auto;
}

.create-grn .date-batch-dialog .date-picker-holder-active {
    padding-left: 0;
}

.mdc-dialog__body .dialog-upperpart .sku-modal-date .rdr-Calendar {
    top: -1339%;
    right: -17%;
}

.create-grn .mdc-dialog__surface .mdc-dialog__body .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 36px;
}

.mdc-dialog__surface .mdc-dialog__body .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 56px;
}

.radio-list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radio-list-wrapper .float-left {
    flex: 1 1;
}

.radio-list-wrapper .float-right.cash-checkbox {
    position: unset;
}

.sales-invoice-create .mdc-dialog .mdc-dialog__body .three .label {
    display: block;
    margin-top: 7px;
    position: relative;
    top: -2.5px;
}

.sales-invoice-create .mdc-dialog .three .Select-control .Select-arrow-zone {
    position: relative;
    right: -10px;
}

.sales-invoice-create .mdc-dialog .mdc-dialog__body .dialog-detail {
    margin-top: 6px;
}

.sales-invoice-create .mdc-dialog .mdc-dialog__body .custom-stock label {
    color: rgba(0, 0, 0, 0.54);
    color: rgba(0, 0, 0, var(--hover-opacity))
}

.sample-csv {
    position: absolute;
    bottom: 12px;
    left: 30px;
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    color: #2196f3;
    color: var(--accent-color);
    font-weight: 500;
}

.sample-csv i.material-icons {
    color: #2196f3;
    color: var(--accent-color);
    opacity: 1;
    margin-top: 2px;
    margin-left: 0;
    margin-right: 5px;
}


/* opening blnc */

.opening-blnc table th:last-child {
    text-align: right;
}


/* srn vat reports */

.srn-vat .display-block .header-bar .center-align .default-margin-top-12 {
    margin-top: 0;
}

.grn-create .mdc-dialog__surface .mdc-dialog__body .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 27px;
}


/* grn srn stock adjustment create dialog styling */

.srn-create .dialog-upperpart .row .grid-padding,
.sales-invoice-create .dialog-upperpart,
.grn-create .dialog-upperpart,
.grn-create .dialog-upperpart .row,
.stock-journal-dialog .dialog-upperpart,
.customer-ledger .dialog-upperpart,
.srn-create .dialog-upperpart {
    padding: 0;
}

.srn-create .dialog-upperpart .fab-div {
    padding: 0px 0px 14px;
}

.srn-create .sales-invoice-number .auto-complete-pop-up label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    bottom: 12px;
    font-weight: 400;
    margin-bottom: 0;
}

.srn-create .sales-invoice-number .auto-complete-pop-up+.success-label {
    position: relative;
    top: -6px;
}

.srn-create .sales-invoice-number .auto-complete-pop-up+.success-label span {
    color: green;
    font-size: 10px;
}

.srn-create .sales-invoice-number .auto-complete-pop-up+.success-label .material-icons {
    margin-left: 0;
    font-size: 21px;
    color: green;
}

.srn-create .sales-invoice-number .auto-complete-pop-up .filter-item+.filter-dropdown-card {
    min-width: 100%;
    top: 126%;
    box-shadow: unset;
    border: 0;
}

.srn-create .sales-invoice-number .auto-complete-pop-up .filter-item+.filter-dropdown-card .autocomplete-list {
    padding: 6px;
    box-shadow: rgb(0 0 0 / 10%) 1px 2px 10px 1px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(231, 231, 237);
    ;
}

.srn-create .sales-invoice-number .auto-complete-pop-up .mdc-textfield {
    height: unset;
}

.srn-create .dialog-upperpart .label {
    position: relative;
    top: -2.5px;
}

.srn-create .sales-invoice-number .mdc-textfield__input:required+.mdc-textfield__label:after {
    content: unset;
}

.customer-ledger .dialog-upperpart .upload {
    padding: 24px;
}

:is(.customer-ledger,
.grn-create) .row_one .mdc-checkbox+label {
    position: relative;
    bottom: -2px;
}

.customer-ledger .row_one .select-css .zindex-2__menu-list {
    max-height: 200px;
    overflow: auto;
}

.sales-invoice-create .cash-checkbox {
    top: 0px;
    left: -8px;
    display: flex;
    align-items: center;
}

.sales-invoice-create .net-amount.dialog-detail,
.sales-invoice-create .rate.dialog-detail {
    border-bottom: 1px solid rgba(0, 0, 0, .5);
    padding-bottom: 8px;
}

.sales-invoice-create .dialog-upperpart .row,
.grn-create .dialog-upperpart .row,
.stock-journal-dialog .dialog-upperpart .row,
.srn-create .dialog-upperpart .row {
    border-bottom: 1px solid #eeeeee;
    border-bottom: 1px solid var(--line-divider-color);
    padding: 24px;
}

.grn-create .dialog-upperpart .row:last-child,
.sales-invoice-create .dialog-upperpart .row:last-child,
.grn-create .dialog-upperpart .row_two>label,
.stock-journal-dialog .dialog-upperpart .row:last-child,
.srn-create .dialog-upperpart .row:last-child {
    border-bottom: 0;
}

.stock-journal-dialog .dialog-upperpart .row_two>label,
.srn-create .dialog-upperpart .row_two>label {
    display: block;
    padding-bottom: 12px;
}

.sales-invoice-create .dialog-upperpart,
.srn-create .dialog-upperpart,
.stock-journal-dialog .dialog-upperpart,
.customer-ledger .dialog-upperpart.overflow-active,
.grn-create .dialog-upperpart {
    max-height: 65vh;
    overflow: auto;
}

.grn-create .dialog-upperpart .mini-fab-button .mdc-fab--mini,
.srn-create .dialog-upperpart .mini-fab-button .mdc-fab--mini {
    left: 0;
}

.srn-create .dialog-upperpart .discount input,
.srn-create .dialog-upperpart .discount input {
    color: #2196f3;
    color: var(--accent-color);
}

.grn-create .mdc-dialog__body .dialog-upperpart .sku-modal-date .rdr-Calendar {
    top: 56px;
    right: unset;
    left: -20px;
}

.grn-create .dialog-upperpart .date-picker-holder-active {
    padding-left: 0;
}

.grn-create .header-right .date-range-picker-wrapper>.default-vertical-padding-24 {
    padding: 0;
}

.grn-create .header-right .date-picker-wrapper .calendar-status {
    display: none;
}

.grn-create .header-right .date-picker-wrapper .rdr-Calendar {
    top: 52px;
}

.grn-create .header-right>.date-picker-wrapper .date-picker-holder-active,
.grn-create .header-right>.date-picker-wrapper .date-picker-holder {
    text-align: right;
}

.account-group .fixed-table-wrapper table th {
    z-index: 1;
}


/* navbar icons styling */

.max-drawer .header .header-wrap::before {
    content: url(/static/media/down_arrow.b6aa12d6.svg);
}

.fym-dialog-body .fym-error span::before {
    content: url(/static/media/err.b4383c05.svg);
    position: relative;
    top: 3px;
}

.mini-drawer .header .header-wrap p img::before,
.max-drawer .header.active .header-wrap::before {
    content: url(/static/media/up_arrow.01887027.svg);
}


/* tab UI */

.react-tabs {
    position: relative;
}

.react-tabs .react-tabs__tab-list {
    padding: 0;
    list-style: none;
}

.react-tabs .react-tabs__tab-list .react-tabs__tab {
    display: inline-block;
    min-width: 80px;
    padding: 0 16px;
    text-align: center;
    color: #6b6c7e;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
}

.react-tabs .react-tabs__tab-list .react-tabs__tab--selected {
    border-bottom: 4px solid #2196F3;
    color: #2196F3;
    outline: 0;
    line-height: 30px;
}


/* users stylign */

.users .dialog-upperpart .password {
    position: relative;
}

.users .fixed-table-wrapper table th {
    z-index: 1;
}

.users .dialog-upperpart .password .pw_display {
    position: absolute;
    top: -20px;
    margin: 0;
    color: rgb(40, 125, 60);
    ;
    font-size: 11px;
}

.users .dialog-upperpart .password .pw_criteria {
    margin: 0;
    color: #d50000;
    font-size: 10px;
    line-height: 13px;
}


/* agents styling */

.agents .fixed-table-wrapper table th {
    z-index: 2;
}


/* grn srn prn salesInvoice Report styling */

.no-data .pagination-wrapper {
    visibility: hidden;
}


/* vat summary filter taxable amount styling */

.input-by-wrap {
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    padding: 24px;
}

.link-voucher:hover {
    color: #2196f3;
    text-decoration: underline;
    cursor: pointer;
}
.pannel-section{margin:48px 70px}.pannel-section h4{margin-bottom:16px;line-height:16px}.pannel-section .pannel-header{margin-bottom:48px}.pannel-section .pannel-header h1{text-transform:capitalize}.pannel-section .disabled_col{cursor:not-allowed}.pannel-section .disabled_col .zindex-2__control--is-disabled{background-color:#FFFFFF}.pannel-section .button{display:flex;justify-content:flex-end}.pannel-section .button .mdc-button{background-color:#2196F3;color:#FFFFFF;margin-right:0px !important}.pannel-section .pannel-card{background-color:#fff;box-shadow:0 1px 4px 0 rgba(0,0,0,0.1);padding:30px;margin-bottom:48px}.pannel-section .pannel-card .checkbox{display:flex;flex-wrap:wrap}.pannel-section .pannel-card .checkbox .mdc-checkbox{width:18px;height:18px;padding:11px}.pannel-section .pannel-card .checkbox span{margin-right:48px}.pannel-section .pannel-card.bill_print .checkbox{display:flex;flex-direction:column}.pannel-section .pannel-card.bill_print .checkbox .mdc-checkbox{position:relative;left:-7px}

:root {
  --max-table-height: 400px;
}

body {
  margin: 0;
}
.rosia-table {
}
.rosia-table td {
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
}

.rosia-table th {
  white-space: nowrap;
}

.rosia-table thead {

}

/* .card-header-bar {
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: #fff;
  line-height: 64px;
}

.card-header-bar input::-webkit-input-placeholder {
  color: white;
}

.card-header-bar input::-moz-placeholder {
  color: white;
}

.card-header-bar input:-ms-input-placeholder {
  color: white;
}

.card-header-bar input:-moz-placeholder { 
  color: white;
} */

.header-menu {
  /*display: flex;*/
  margin-left: auto;
}

.app-header {
  background: var(--primary-color);
  top: 0;
  color: #fff;
  width: 100%;
  position: fixed;
  z-index: 400;
  padding: 0 24px;
  line-height: 64px;
  vertical-align: middle;
}

.app-header h1 {
  display: inline-block;
  color: #fff;
}

.app-header > span > i {
  vertical-align: inherit !important;
}

.cursor-pointer {
  cursor: pointer;
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}
.center-align {
  text-align: center;
}

.grid-header {
  text-align: left;
  cursor: pointer;
}

.grid-header-no-cursor {
  text-align: left;
}

.grid-num-header {
  text-align: right;
  cursor: pointer;
}


.app-container {
  padding: 24px;
  margin-top: 60px;
}

.pagination-wrapper {
  background: #fbfbfb;
  line-height: 48px;
  padding: 0px 10px;
}

.filter-wrapper {
  margin-top: 88px;
  /*overflow-y: scroll;*/
}
.filter-head {
  padding: 16px 24px;
  border-bottom: 1px solid #eeeeee;
}
.filter-body {
  padding: 16px 24px;
}

.flex-display {
  display: flex;
}

.filter-arrow-icon {
  margin: auto 8px auto auto;
}

.filter-title {
  cursor: pointer;
  display: flex;
}

.login-wrapper {
}

.invalid-message {
  color: #ef5350;
  font-size: 12px;
}

/*.linear-progress-wrapper {
    padding: 10px 0px;
}*/

.active-link {
  border-left: 2px solid #FF4081;
}

.nav-right-item {
  float: right;
  margin-right: 24px;
}

.temp-progress-wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  z-index: 1000;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

/* .create-fab-button {
  position: fixed;
  bottom: 85px;
  right: 56px;
} */

.Select-control {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0px !important;
  border-collapse: collapse;
  box-shadow: none !important;
}

.grid-padding {
  padding: 12px 0px 16px;
}

/* extra css UI */
/* .card-header-bar .mdc-temporary-drawer__drawer{
  line-height: 0;
}

.mdc-temporary-drawer__drawer .filter-wrapper {
    margin-top:24px;
}
.mdc-temporary-drawer__drawer{
    margin-top:64px;
}
.mdc-temporary-drawer__drawer  .default-horizontal-padding-24 {
    line-height:21px;
} */


@media print {
   .linear-progress-wrapper ,
    .print_display_none{
        display:none ;
    }
    table tfoot td.pad-l-10{
        padding-left:10px !important;
    }
    .display_print_only{
        display:table-cell !important;
    }

    .portrait,
    .portrait-type,
    .landscape,
    .visibility-shown{
        visibility: visible !important;
    }
    .custom-three-input-wrapper label,
    .active-opacity-text{
        color:#000000 !important;
    }
    p{
        margin:0;
    }
    html, body {
        height: 100%;
    }
    .custom-three-input-wrapper label,
    .active-opacity-text{
        color:#000000 !important;
    }
    @page {
        margin: 0 !important;
        /* size: A4 portrait !important; */
        /* size: A4 landscape !important; */
        counter-increment: page;
        counter-reset: page 1;
    }
    .message-snackbar,
    ::-webkit-scrollbar ,
    .content .mini-drawer ,
    .mdc-permanent-drawer ,
    .nav-bar,nav,.newinvoice-btn-wrapper,.no-print{
        display: none !important;
    }
    .content{
        margin-top:0 !important;
    }
    .content-body .app-body,
    .content{
        height:unset !important;
        overflow:unset !important;
    }
    .pad-b-24,
    .pad-24-0-24-10{
        padding:0 !important;
    }
    .header-title{
        font-size:16px !important;
        line-height:18px !important;
        font-weight:500 !important;
    }
    .header-right-content {
        font-size: 13px !important;
    }
    .header-bar .mdc-layout-grid__inner,
   .first-header-bar .mdc-layout-grid__inner{
        display:inline-block!important;
        width:100%;
    }
    .header-bar .mdc-layout-grid__cell,
    .first-header-bar .mdc-layout-grid__cell{
        width:33.33%;
        display:inline-block;
        vertical-align: text-top;
    }
    :is(.header-bar,.first-header-bar) .mdc-layout-grid__cell:nth-child(2) h2{
        text-align:center;
        font-size:18px;
    }
    :is(.header-bar,.first-header-bar) .mdc-layout-grid__cell:nth-child(2) h5{
        text-align:center;
        font-size:14px;
        font-weight:400;
    }
    .header-middle-content{
        text-align:left !important;
    }
    .three-input-wrapper .mdc-layout-grid .mdc-textfield__input{
        font-size:13px;
    }
    .three-input-wrapper  .mdc-layout-grid__cell--span-5,
   .three-input-wrapper  .mdc-layout-grid__cell--span-3{
        grid-column-end: span 2 !important;
    }
    .total-section-wrapper .mdc-layout-grid__cell--span-3{
        grid-column-end: span 7 !important;
    }
    .mdc-layout-grid{
        padding:0 !important;
    }
    .content-body > .mdc-layout-grid {
        padding-top: 0px !important;
        background-color:#FFFFFF !important;
    }
    .print-invoice .mdc-layout-grid .mdc-layout-grid__inner{
        display:flex !important;
    }
    .print-invoice .mdc-layout-grid .mdc-layout-grid__inner>div{
        flex: 1 1;
    }
    .card-body .fixed-table-wrapper .sku{
        width:350px;
    }
    table th, table td{
        padding:0 10px !important;
        font-size:13px !important;
        white-space: nowrap !important;
    }
    table td:last-child, table th:last-child{
        padding-right:0 !important;
    }
    table td:nth-child(2), table th:nth-child(2){
        padding-left:0 !important;
    }
    table td:first-of-type, table th:first-of-type {
        padding-left:0 !important;
        width: 18px !important;
        white-space: nowrap !important;
    }
    table {
        table-layout: fixed !important;
        min-width: 100% !important;
    }
    table tbody tr {
        width: 100%;
        border-bottom: none !important;
    }
    table tbody td{
        color:#000000 !important;
        opacity: unset !important;
        line-height: 24px !important;

    }
    table thead th{
        color: #000000 !important;
        opacity: unset !important;
        font-weight: 500 !important;
        line-height: 28px !important;
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
        background: rgb(244, 244, 244) !important;
    }
    table tbody tr td:nth-child(2) {
        width: auto;
    }

    table tbody tr td.id {
        width: unset !important;
        width:40px !important;
    }
    table tbody tr td.title {
        width: auto !important;
    }

    .total-section-wrapper .mdc-layout-grid {
        padding:0px !important;
    }
    .first-header-bar {
        border-bottom: none !important;
    }
    .header-bar {
        margin-top: 0 !important;
        border-bottom: none !important;
    }
    .card-body {
        border: none;
        margin-top: 0px;
        box-shadow: none!important;
    }
    .print-active {
        display: block;
    }
    table.print-active tfoot {
        width: 100% !important;
        display:inline-table !important;
    }
    table.print-active tfoot tr ,table.print-active tfoot tr{
        width: 100%;
        border-bottom: none;
    }
    table.print-active th, table.print-active td {
        line-height: unset;
        background: unset;
        border-bottom: none;
        padding-left: 0px;
    }

    table.header > thead th:first-child {
        width: 54%;
        padding: 0px;
    }
    table.footer > tfoot td{
        width: 40%;
        color: #000000 !important;
        padding-right: 0 !important;
    }
    .fixed-table-height{
        box-shadow: none !important;
        /* min-height: 865px !important; */
        font-size: 12px !important;
    }
    .hr {
        margin: 5px 0 !important;
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
    }
    .extra-content {
        content: '';
        display: block;
        margin-right: 100px;
    }
    table.header-table{
        top: 0;
        display: none;
    }
    table.header-table tr td{
        content:'';
        display: none;
    }
    table.footer-table{
        position: fixed;
        bottom: 0;
        page-break-after: always;
    }
    table.footer-table tr td{
        text-align: center;
        color: #000;
        line-height: 60px;
        font-size: 12px;
        content:'----' ;
    }
    table {
        page-break-after:auto;
    }
    tr    { page-break-inside:avoid; page-break-after:auto }
    td    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group !important; }
    tfoot { display:table-footer-group }
    .footer-block {
        page-break-after: always;
    }
    .footer-block{
        position:absolute;
        bottom:-3px;
        left:0;
        width:100%;
        height:20px;
        color: #000000 !important;
        opacity: unset !important;
    }
    .fixed-table-wrapper.total-footer + .footer-block{
   background-color:#FFFFFF;
    }
    .print-body {
        position: relative;
        height:1200px;
        padding:20px;
        overflow:auto;
    }
    .fixed-table-wrapper {
        box-shadow: none !important;
        width: auto !important;
        margin-top:16px;
        min-height:800px;
    }

    /* for sales invoice only start  */

    .portrait .footer.total-section-wrapper,
    .footer-totalList{
        position:absolute;
        width:95%;
        bottom:10px;
        padding:0 20px !important;
        left:0;
    }


    .agent_info .agent_value{
        margin-left:6px;
    }
    /* for sales invoice only end  */
    .more-settings {
        display: none !important;
    }
    #more-settings {
        display: none !important;
    }
    .tfoot-td {
        line-height: 23px !important;
    }
    .line-height-none {
        line-height: 0 !important;
    }
    .signature-line {
        width: 18%;
        height: 10px;
        margin: 26px 0px 0px;
        border-bottom: 1px solid rgba(0,0,0,0.54);
    }
    .header-middle-content {
        border-bottom: none !important;
    }

    table.voucher-table td,th {
        padding: 0 16px;
        white-space: normal;
    }
    table.voucher-table th {
        line-height: unset;
        width: 100px !important;
        white-space: normal;
        padding: 2px 10px 8px;
        font-weight: 500 !important;
        font-size: 14px;
        color: #000000;
        line-height: 14px !important;
    }
    table.voucher-table td {
        vertical-align: top;
        line-height: unset;
        white-space: normal;
        font-weight: var(--regular);
        font-size: 12px;
        padding: 8px 12px 7px;
        max-height: 62px !important;
        min-height: 30px;
        overflow: hidden;
        color: #000000 !important;
        line-height: 14px !important;
    }
    table.voucher-table td:not(:nth-of-type(2)):not(:first-child):not(:last-child),table.voucher-table th:not(:nth-of-type(2)):not(:first-child):not(:last-child){
        width: 8% !important;
    }
    table.voucher-table td:first-child,table.voucher-table th:first-child{
        width: 20px !important;
    }
    table.voucher-table td:nth-child(2),table.voucher-table th:nth-child(2){
        width: 20% !important;
    }
    table.voucher-table td:last-child,table.voucher-table th:last-child{
        width: 20% !important;
    }
    table.pdf-table{
        font-size: 12px;
    }
    table.pdf-table td, th {
        padding: 0 16px;
        white-space: normal;
    }
    table.pdf-table th {
        line-height: unset;
        font-size: 12px;
        font-weight: normal;
        width: 100px !important;
        white-space: normal;
        padding: 10px 12px;
    }
    table.pdf-table td {
        vertical-align: top;
        line-height: unset;
        white-space: normal;
        font-weight: var(--regular);
        font-size: 11px;
        padding: 9px 12px;
    }
    table.pdf-table tr {
        border-bottom:1px solid rgba(0,0,0,0.54);
    }
    td:first-of-type, th:first-of-type {
        padding-left: 24px;
    }
    td:last-of-type, th:last-of-type {
        padding-right: 24px;
    }
    .pdf-table-wrapper {
        height: 970px !important;
    }
    .zoom-reduce-medium {
        zoom:0.8;
        -moz-transform: scale(0.8);
        -moz-transform-origin: 0 0;
    }
    .zoom-reduce {
        zoom:0.7;
        -moz-transform: scale(0.7);
        -moz-transform-origin: 0 0;
    }
    .zoom-less-reduce {
        zoom: 0.90;
        -moz-transform: scale(0.90);
        -moz-transform-origin: 0 0;
    }
    .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline) {
        max-width: unset !important;
        height: 25px !important;
    }
    .header-middle-content {
        padding: 0 !important;
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }

    table.total-section-wrapper .total-content-data{
        padding:0 !important;
    }
    table.total-section-wrapper  .tfoot-td .total_value,
    table.total-section-wrapper .total-content-data.item_value{
        width:10% !important;
        white-space: nowrap !important;
        padding-left:10px !important;
    }
    .page-number {
        font-size: 11px;
        color: rgba(0,0,0,0.6);
    }
    .report-table-wrapper {
       height: 860px !important;
    }
    .report-table-footer {
        height:850px !important;
    }
    .pdf-body {
        min-height:990px !important;
    }
    .lg-pdf-body {
        min-height: 1450px !important;
        padding-bottom: 40px !important;
    }
    .left-footer .mdc-layout-grid {
        padding-left: 0px !important;
    }
    .signature-content{
        position: relative;
        padding-left: 12px;
        float: left;
    }

    .signature-content:after {
        content:"__________________________";
        position: absolute;
        top:25px;
        left:-2px;

    }
    .jv-max-width {
        width: 100% !important;
    }
    .signature-content {
        width: 30% !important;
    }
    tr.hr-line {
        border-top: 1px solid rgba(0,0,0,0.54);
    }
    .payment-total-footer {
        min-height: 700px;
    }
    .header-border-bottom {
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
        padding: 0px 0px 8px !important;
    }
    table.footer > tfoot.jv-footer td{
        width: 26%;
    }
    .total-section-wrapper .tfoot-td {
        padding:24px !important;
    }
    .total-section-wrapper .tfoot-td .mdc-layout-grid:first-child{
        padding:0 !important;;
    }
    .total-section-wrapper .tfoot-td {
        padding:5px 0 !important;
    }
    .total-section-wrapper .tfoot-td.total{
        padding-bottom:0 !important;
        line-height:10px !important;
    }
    .tfoot-td.total-word .left{
        padding-right:6px;
    }
    .tfoot-td.total-word .right{
        font-style: italic;
    }
    .total-section-wrapper tr td .authorized{
        padding-top:4px;
    }
    .signature-line{
        margin:0 !important;
    }
    .total-section-wrapper tr td .remark,
    .total-section-wrapper .tfoot-td .mdc-layout-grid:first-child{
        padding:0 !important;;
    }
    .total-section-wrapper .tfoot-td .span-wrapper{
        width:unset;
        float:unset;
    }
    .total-section-wrapper .tfoot-td  .td-wrap{
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
    }
    .input-print{
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        border-bottom: 1px solid rgba(0,0,0,0.54);
    }
    .input-print .text-area.print{
        width:100%;
        word-break: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
         overflow:hidden;
         text-overflow: ellipsis;
         display: -webkit-box;
        margin:0;
        font-size:13px;
    }
    .continue-nxt{
        position: absolute;
        bottom:0px;
    }

    /* portrait-type styling */
    .portrait-type .print-body{
        height:1200px !important;
    }
    .portrait-type .total-section-wrapper .mdc-layout-grid__cell--span-3{
        grid-column-end: span 3 !important;
    }
    .portrait-type .total-section-wrapper .grid__cell--span-8{
        grid-column-end: span 4 !important;
    }
    .portrait-type table th{
        word-spacing: -1px;
    }
    .portrait-type table tr{
        height:32px !important;
    }
    .portrait-type table th,
    .portrait-type table td{
    padding:5px !important;
    line-height:unset !important;
    }
    .portrait-type table .table-tfoot{
        border-top:1px solid rgba(0,0,0,0.54);
    }
    .portrait-type  .jv-footer td{
        padding:0 !important;
    }
    .portrait-type .payment-total-footer{
        min-height:0 !important;
    }
    .portrait-type .sign{
        display:flex;
        justify-content: space-between;
        margin-top:20px;
    }
    .portrait-type .display-none{
        display:none;
    }
    .portrait-type .signature-content{
        display: inline-block;
        width:unset !important;
        margin:auto;
        padding:0;
        text-align: center;
    }
    .portrait-type .signature-content span{
        display: inline-block;
        margin-left:35px;
    }
    .portrait-type .table-tfoot td{
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
        border-top: 1px solid rgba(0,0,0,0.54) !important;
        font-weight: 600 !important;
    }
    .portrait-type .page-tfoot-end{
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
    }
    .portrait-type .page-tfoot{
        border-top: 1px solid rgba(0,0,0,0.54) !important;
    }
    .portrait-type .jv.card-body{
        height:unset !important;
    }
    .portrait-type .jv.card-body .fixed-table-wrapper{
        height:950px;
    }
    .portrait-type .mdc-layout-grid {
        padding:0 !important;
    }
    .portrait-type .jv-footer tr{
        height:25px !important;
    }

.total_quantity_td{
    position: relative;
}
.batch-disable{
    position: absolute;
    left: 39%;
    top: 17px;
}
.batch-enable{
    position: absolute;
    left:45%;
    top: 6px;
}
.invoice_value{
    text-transform: capitalize;
}
}
@media print and (max-width: 1122.51px){
    .print_display_none_A4{
        display:none;
    }
}
 @media print and (max-width: 8.27in){
 }
 @media print and (max-width:5.83in) {
    /* styling for A5 */
    body{
        zoom:70.4912%;
    }
}


    .printDetailClass body {
        color: #000000 !important;
        opacity: unset !important;
        border: 1px solid white;
        height: 99%;
        page-break-after: avoid;
        page-break-before: avoid;
    }

    .printDetailClass .nav-bar, .printDetailClass nav, .printDetailClass .newinvoice-btn-wrapper, .printDetailClass .no-print {
        display: none;
    }

    .printDetailClass .content {
        margin-top: 0px !important;
    }

    .printDetailClass .content-body > .mdc-layout-grid {
        padding-top: 0px !important;
    }

    .printDetailClass .content .mini-drawer {
        display: none;
    }

    .printDetailClass .total-section-wrapper .mdc-layout-grid {
        padding: 0px !important;
    }

    .printDetailClass .first-header-bar {
        margin-top: 10px !important;
        border-bottom: none !important;
    }

    .printDetailClass .header-bar {
       /* margin-top: 10px !important;*/
        padding-top: 0px !important;
        border-bottom: none !important;
    }

    .printDetailClass .card-body {
        width: 100%;
        border: none;
        margin-top: 0px;
        box-shadow: none !important;
        min-height: 1160px !important;
    }
    .printDetailClass .voucher-print .card-body {
        min-height: unset !important;
    }

    .printDetailClass .print-active {
        display: block;

    }

    .printDetailClass table.print-active tfoot {
        display: table-footer-group;
        width: 100% !important;

    }

    .printDetailClass table.print-active tfoot tr, table.print-active tfoot tr {
        width: 100%;
        border-bottom: none;
    }

    .printDetailClass table.print-active th, table.print-active td {
        line-height: unset;
        background: unset;
        border-bottom: none;
        padding-left: 0px;

    }

    .printDetailClass table.header > thead th:first-child {
        width: 54%;
        padding: 0px;
    }

    .printDetailClass table.footer > tfoot td {
        width: 40%;
        color: #000000 !important;
        opacity: 0.8 !important;
        padding-right: 0 !important;
    }

    .printDetailClass .fixed-table-height {
        box-shadow: none !important;
        min-height: 900px !important;
        font-size: 12px !important;
    }

    .printDetailClass table.data-table {
        table-layout: fixed !important;
        min-width: 98% !important;
    }

    .printDetailClass table.data-table tbody tr {
        width: 100%;
        border-bottom: none !important;
    }

    .printDetailClass table.data-table tbody td {
        color: #000000 !important;
        opacity: unset !important;
        line-height: 24px !important;

    }

    .printDetailClass table.data-table thead th {
        color: #000000 !important;
        opacity: unset !important;
        font-weight: 500 !important;
        line-height: 28px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
    }

    .printDetailClass table.data-table tbody tr td:nth-child(2) {
        width: 300px !important;
    }

    .printDetailClass .total-footer {
        min-height: 645px;
    }

    .printDetailClass .hr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
    }

    .printDetailClass .extra-content {
        content: '';
        display: block;
        margin-right: 100px;
    }

    .printDetailClass table.header-table {
        top: 0;
        display: none;
    }

    .printDetailClass table.header-table tr td {
        content: '';
        display: none;
    }

    .printDetailClass table.footer-table {
        position: fixed;
        bottom: 0;
        page-break-after: auto;
    }

    .printDetailClass table.footer-table tr td {
        text-align: center;
        color: #000;
        line-height: 60px;
        font-size: 12px;
        content: '----';
    }


    .printDetailClass table {
        page-break-after: auto;
    }

    .printDetailClass tr {
        page-break-inside: avoid;
        page-break-after: auto
    }

    .printDetailClass td {
        page-break-inside: avoid;
        page-break-after: auto
    }

    .printDetailClass thead {
        display: table-header-group !important;
    }

    .printDetailClass tfoot {
        display: table-footer-group
    }

    .printDetailClass .footer-block {
        page-break-after: always;
    }
    .printDetailClass .print-body:last-child .footer-block{
        page-break-after: avoid !important;
    }

    .printDetailClass .footer-block span {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 20px;
        color: #000000 !important;
        opacity: unset !important;
    }

    .printDetailClass .print-body {
        position: relative;
        padding-bottom: 25px;
    }


    .printDetailClass .tfoot-td {
        line-height: 23px !important;
    }

    .printDetailClass .line-height-none {
        line-height: 0 !important;
    }

    .printDetailClass .signature-line {
        width: 18%;
        height: 10px;
        margin: 26px 0px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    }

    .printDetailClass .header-middle-content {
        border-bottom: none !important;
    }

    .printDetailClass .fixed-table-wrapper {
        box-shadow: none !important;
        width: auto !important;
    }

    .printDetailClass table.pdf-table {
        font-size: 12px;
    }

    .printDetailClass  table.pdf-table td, th {
        padding: 0 16px;
        white-space: normal;
    }

    .printDetailClass table.pdf-table th {
        line-height: unset;
        font-size: 12px;
        font-weight: normal;
        width: 100px !important;
        white-space: normal;
        padding: 10px 12px;
    }

    .printDetailClass table.pdf-table td {
        line-height: unset;
        white-space: normal;
        font-weight: var(--regular);
        font-size: 11px;
        padding: 10px 12px;
    }

    .printDetailClass table.pdf-table tr {
        border-bottom: 1px solid #f7f7f7;
    }

    .printDetailClass td:first-of-type, th:first-of-type {
        padding-left: 24px;
    }

    .printDetailClass td:last-of-type, th:last-of-type {
        padding-right: 24px;
    }
    .printDetailClass .voucher-print tfoot td:first-of-type {
        padding-left: unset !important;
    }
    .printDetailClass .voucher-print tfoot td:last-of-type {
        padding-right:  unset !important;
    }

    .printDetailClass .pdf-table-wrapper {
        height: 970px !important;
    }

    .printDetailClass .zoom-reduce {
        zoom: 0.7;
    }

    .printDetailClass .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline) {
        max-width: unset !important;
        height: 25px !important;
    }

    .printDetailClass .first-header-bar .mdc-layout-grid {
        padding: 10px 24px 0px !important;
    }

    .printDetailClass .header-middle-content {
        padding: 5px 0px 8px !important;
    }

    .printDetailClass .three-input-wrapper .mdc-layout-grid {
        padding: 10px 24px 8px !important;
    }
    .printDetailClass .custom-three-input-wrapper .mdc-layout-grid {
        padding:15px 24px 0px !important;
    }
    .printDetailClass .total-section-wrapper .mdc-layout-grid {
        padding: 0px 24px 0px !important;
    }
    .printDetailClass .total-section-wrapper .mdc-layout-grid:first-child{
        padding:0px 24px 0px!important;
    }
    .printDetailClass table.total-section-wrapper tr td.total-content-data {
        line-height: 24px !important;
    }

    .printDetailClass .page-number {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.6);
    }

    .printDetailClass .left-footer .mdc-layout-grid {
        padding-left: 0px !important;
    }
    .printDetailClass .header-border-bottom {
        border-bottom: 1px solid #c5c5c5 !important;
        padding: 0px 0px 8px !important;
    }
    .printDetailClass .rosia-srn-card {
        min-height: 1120px !important;
    }
    @media print {
        .printDetailClass table.total-section-wrapper tr td.total-content-data {
            line-height: 3px !important;
        }
    }



